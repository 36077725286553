import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { compose } from 'recompose';
import axios from 'axios';

import RatingEditor from '../components/RatingEditor';

const styles = theme => ({
  ratings: {
    marginTop: theme.spacing(2),
    "overflow-y": 'auto',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
});

const API = process.env.REACT_APP_API || 'https://jinzu-backend.flatedges.xyz';

class RatingForm extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      loading: false,
      rating: null,
      error: null,
      rating_name: props.match.params.name,
      webcamComponent: null
    }
    setTimeout(()=>{
      this.setState({loading:false,authenticated:true});
    },3000)

  }

  componentDidMount() {
    const rating_name = this.state.rating_name;
    if(rating_name !== null) {
      this.getRating(rating_name);
    }
  }

 setPhotoState = (webcam, webcamComponent) => {
    if(webcam === null || webcamComponent === null) {
      return;
    }
    const photo = webcam.getScreenshot();
    this.setState({ webcamComponent: webcamComponent })
    webcamComponent.setState({ photo: photo })
  }

  saveRating = async (rating) => {
    this.uploadPhoto(rating.name);
    rating.user = "jason"
    if (rating.updated) {
      rating["updated"] = Math.floor(Date.now() / 1000);
      await this.fetch('put', `/ratings?name=${rating.name}`, rating);
    } else {
      rating["updated"] = Math.floor(Date.now() / 1000);
      await this.fetch('post', '/', rating);
    }

    this.props.history.goBack();
  }

  uploadPhoto = async (key) => {
    if(key.length < 1) {
      console.log("Not uploading, no key");
      return;
    }
    if(this.state.webcamComponent == null || this.state.webcamComponent.state.photo == null) {
      console.log("Not uploading, no photo");
      return;
    }
    const response = await this.getPresignedUrlFromS3(key);
    var url = response.data;
    this.sendPresignedUrlWithFile(
      url,
      this.state.webcamComponent.state.photo
    )
    console.log("Photo uploaded");
  };

  getPresignedUrlFromS3 = async (key) => {
    return axios.get(`${API}/pictures?key=${key}`,{
      headers: {
        'X-Jinzu-API-Token': window.localStorage.getItem('jinzu-auth'),
      }
    })
  };

  sendPresignedUrlWithFile = async (url, file) => {
    try {
      await url;
      const buffer = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64');
      const result = await axios.put(url, buffer, {
        headers: {
          'Content-Type':     'image/jpeg',
          'Content-Encoding': 'base64'
        },
      });
      return result;
    }

    catch (e) {
      console.log(e.message);
      throw new Error(e);
    }
  };

  async fetch(method, endpoint, body) {
    try {
      const response = await fetch(`${API}${endpoint}`, {
        method,
        body: body && JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
          'X-Jinzu-API-Token': window.localStorage.getItem('jinzu-auth'),
          accept: 'application/json',
        },
      });
      return await response.json();
    } catch (error) {
      console.error(error);

      this.setState({ error });
    }
  }

  async getRating(name) {
    this.setState({ loading: false, rating: (await this.fetch('get', `/ratings/${name}`)) });
  }

  renderRatingEditor = () => {
    const rating = this.state.rating;
    const rating_name = this.state.rating_name
    if (this.state.loading) return null;

    if (!rating && rating_name !== 'new') return <Redirect to="/ratings" />;

    return <RatingEditor rating={rating} onSave={this.saveRating} setPhotoState={this.setPhotoState}/>;
  };

  render() {
    return (
      <Fragment>
        {this.state.rating !== null || this.state.rating_name === 'new' ? (
          this.renderRatingEditor()
        ) : (
          !this.state.loading && !window.token_id && <CircularProgress />
        )}
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
)(RatingForm);
