import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles,
  AppBar,
  Button,
  Toolbar,
  Link as MaterialLink,
} from '@material-ui/core';
import { compose } from 'recompose';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
});

class AppHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loggedIn: false
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setLoginState(), 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  setLoginState() {
    var currentTime = Math.floor(Date.now() / 1000)
    if(!localStorage.hasOwnProperty('jinzu-auth') || !localStorage.hasOwnProperty('jinzu-auth-expires')) {
      this.setState({loggedIn: false});
      return;
    }
    if(localStorage.hasOwnProperty('jinzu-auth') && localStorage.hasOwnProperty('jinzu-auth-expires')) {
      if(currentTime > window.localStorage.getItem('jinzu-auth-expires')) {
        window.localStorage.removeItem('jinzu-auth');
        window.localStorage.removeItem('jinzu-auth-expires');
        this.setState({loggedIn: false});
        return;
      } else {
        this.setState({loggedIn: true});
        return;
      }
    }
    this.setState({loggedIn: false});
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <MaterialLink className={classes.title} variant="h6" color="inherit" href="/" underline="none">Jinzu</MaterialLink>
            <Button color="inherit" component={Link} to="/ratings">Ratings</Button>
            { this.state.loggedIn ? "" : <Button color="inherit" href="/sign_in">Sign in</Button> }
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

export default compose(
  withStyles(styles),
)(AppHeader);
