import React from 'react';
import {
  withStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import WebcamCapture from './WebcamCapture';
import Rating from '@material-ui/lab/Rating';

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 'calc(100% - 200px)',
    overyflowY: "auto",
  },
  modalCard: {
    width: '90%',
    maxWidth: 500,
  },
  modalCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  hiddenField: {
    visibility: 'hidden'
  }
});

class RatingEditor extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.state = {}
    if(this.props.rating) {
      this.state.taste_rating = parseFloat(this.props.rating.taste_rating);
    }
    else {
      this.state.taste_rating = 0;
    }
    if(this.props.rating) {
      this.state.value_rating = parseFloat(this.props.rating.value_rating);
    }
    else {
      this.state.value_rating = 3;
    }
  }

  defaultOriginalName () {
    if(this.props.rating) {
      return this.props.rating.name;
    }
    else {
      return "";
    }
  }

  isDisabled() {
    if(window.localStorage.getItem('jinzu-auth')) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <Form initialValues={this.props.rating} onSubmit={this.props.onSave}>
        {({ handleSubmit }) => (
            <Card className={this.props.classes.modalCard}>
              <form onSubmit={handleSubmit}>
              <CardContent className={this.props.classes.modalCardContent}>
                <Field name="name">
                  {({ input }) => <TextField label="Name" autoFocus {...input}/>}
                </Field>
                <Field name="description">
                  {({ input }) => (
                    <TextField
                      className={this.props.classes.marginTop}
                      label="Description"
                      multiline
                      rows={4}
                      {...input}
                    />
                  )}
                </Field>
                <Field name="taste_rating" type="radio" readOnly={true}>
                  {props => (
                    <div className="MultiInputLabel-shrink MultiFormLabel-filled">
                    <Typography component="legend">Taste</Typography>
                    <Rating
                      name={props.input.name}
                      defaultValue={this.state.taste_rating}
                      precision={0.5}
                      onChange={props.input.onChange}
                      size="large"
                      readOnly={this.isDisabled()}
                    />
                    </div>
                  )}
                </Field>
                <Field name="value_rating" type="radio">
                  {props => (
                    <div className="MultiInputLabel-shrink MultiFormLabel-filled">
                    <Typography component="legend">Value for money</Typography>
                    <Rating
                      name={props.input.name}
                      defaultValue={this.state.value_rating}
                      precision={0.5}
                      onChange={props.input.onChange}
                      size="large"
                      readOnly={this.isDisabled()}

                    />
                    </div>
                  )}
                </Field>
                <Field name="original_name" initialValue={this.defaultOriginalName()} className={this.props.classes.hiddenField}>
                  {({ input }) => <TextField label="original_name" className={this.props.classes.hiddenField} disabled={this.isDisabled()} autoFocus {...input} />}
                </Field>
              </CardContent>
              <CardContent className={this.props.classes.modalCardContent}>
                { this.props.rating && this.props.rating.picture_url ?
                  (
                    <img src={this.props.rating.picture_url} width={300} height={300} alt=""></img>
                  ) : (
                    <WebcamCapture setPhotoState={this.props.setPhotoState}/>
                  )
                }
              </CardContent>
              <CardActions>
                <Button size="small" variant="contained" color="primary" type="submit" disabled={this.isDisabled()}>Save</Button>
                <Button size="small" onClick={() => this.props.history.goBack()}>Cancel</Button>
              </CardActions>
            </form>
          </Card>
      )}
    </Form>
    )
  }
}


export default compose(
  withRouter,
  withStyles(styles),
)(RatingEditor);
