import React, { Fragment } from 'react';
import {
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
});

class SignInButton extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      autoPrompt: this.props.autoPrompt || "false"
    }
    window.successCallback = this.onSuccess;
  }
    onSuccess(response) {
    var base64Url = response.credential.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const responsePayload = JSON.parse(jsonPayload);
    window.localStorage.setItem('jinzu-auth', response.credential)
    window.localStorage.setItem('jinzu-auth-expires', responsePayload.exp)
    window.location = "/ratings";
  }

  render() {
    return (
      <Fragment>
      <div id="g_id_onload"
           data-client_id={process.env.REACT_APP_GOOGLE_APP}
           data-callback="successCallback"
           data-auto_prompt={this.state.autoPrompt}
      ></div>
      <div className="g_id_signin" data-type="standard"></div>
      </Fragment>
    );
  }
}
export default compose(
  withRouter,
  withStyles(styles),
)(SignInButton);
