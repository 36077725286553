import React, {createRef} from 'react';
import {
  withStyles,
  CardContent,
  Button
} from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Webcam from "react-webcam";
import RedoIcon from '@material-ui/icons/Redo';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  modalCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class WebcamCapture extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      ref: createRef(),
      webcamHidden: false,
      photoButtonDisabled: false,
      photoButtonHidden: true,
      imageHidden: true,
      retakeButtonHidden: true,
      retakeButtonDisabled: true,
      photo: null
    }
  }

  disableWebcamButton = (event) => {
    this.props.setPhotoState(this.state.ref.current, this)
    this.setState({ webcamHidden: true })
    this.setState({ photoButtonDisabled: true })
    this.setState({ photoButtonHidden: true })
    this.setState({ imageHidden: false })
    this.setState({ retakeButtonHidden: false })
    this.setState({ retakeButtonDisabled: false })
  }

  resetWebcam = (event) => {
    this.setState({ webcamHidden: false })
    this.setState({ photoButtonDisabled: false })
    this.setState({ photoButtonHidden: false })
    this.setState({ imageHidden: true })
    this.setState({ retakeButtonHidden: true })
    this.setState({ retakeButtonDisabled: true })
    this.setState({ photo: null })
  }

  render() {
    const videoConstraints = {
      width: 300,
      height: 300,
      facingMode: "environment"
    };
    const { classes } = this.props;

    return (
      <CardContent className={this.props.classes.modalCardContent}>
        { this.state.photo === null ? (
            <>
            <Webcam
              audio={false}
              ref={this.state.ref}
              hidden={this.state.webcamHidden}
              screenshotFormat="image/jpeg"
              width={videoConstraints.width}
              height={videoConstraints.height}
              videoConstraints={videoConstraints}
            />
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<CameraAltIcon />}
              onClick={(e) => this.disableWebcamButton(e)}
              disabled={this.state.photoButtonDisabled}
              hidden={this.state.photoButtonHidden}
            >
              Take photo
            </Button>
            </>
          ) : (
            <>
            <img
              src={this.state.photo}
              hidden={this.state.imageHidden}
              width={videoConstraints.width}
              height={videoConstraints.height}
              alt=""
            />
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              hidden={this.state.retakeButtonHidden}
              disabled={this.state.retakeButtonDisabled}
              startIcon={<RedoIcon />}
              onClick={(e) => this.resetWebcam(e)}
            >
              Take again
            </Button>
            </>
          )
        }

        </CardContent>
    );
  }
}
export default compose(
  withRouter,
  withStyles(styles),
)(WebcamCapture);
