import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  withStyles,
  Typography,
  Fab,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import { orderBy } from 'lodash';
import { compose } from 'recompose';
import InfiniteScroll from "react-infinite-scroll-component";

import ErrorSnackbar from '../components/ErrorSnackbar';
import Rating from '@material-ui/lab/Rating';

const styles = theme => ({
  ratings: {
    marginTop: theme.spacing(2),
    "overflow-y": 'auto',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(9),
    right: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(8),
      right: theme.spacing(3),
    },
  },
});

const API = process.env.REACT_APP_API || 'https://jinzu-backend.flatedges.xyz';

class RatingsManager extends Component {
  state = {
    loading: true,
    ratings: [],
    error: null,
    hasMore: false,
    last_evaluated_key: null,
  };

  componentDidMount() {
    this.getRatings();
  }

  async fetch(method, endpoint, body) {
    try {
      const response = await fetch(`${API}${endpoint}`, {
        method,
        body: body && JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
          'X-Jinzu-API-Token': window.localStorage.getItem('jinzu-auth'),
          accept: 'application/json',
        },
      });
      return await response.json();
    } catch (error) {
      console.error(error);

      this.setState({ error });
    }
  }

  fetchMoreRatings = () => {
    this.getRatings();
  }

  async getRatings() {
    var query_string = '/';
    if(this.state.last_evaluated_key) {
      query_string += `?last_evaluated_key=${btoa(this.state.last_evaluated_key.name)}`;
    }

    var result = (await this.fetch('get', query_string)) || [];

    var ratings = result

    if(result.items) {
      ratings = JSON.parse(result.items);
    }

    var hasMore = result.last_evaluated_key ? true : false;

    ratings = this.state.ratings.concat(ratings);

    this.setState({
      loading: false,
      ratings: ratings,
      last_evaluated_key: result.last_evaluated_key || null,
      ratings_count: this.state.ratings_count + result.count,
      hasMore: hasMore,
    });

  }

  async deleteRating(rating) {
    if (window.confirm(`Are you sure you want to delete "${rating.name}"`)) {
      await this.fetch('delete', '/', {"name": rating.name});
      this.getRatings();
    }
  }

  averageRatingValue(rating) {
    if(rating.value_rating || rating.taste_rating) {
      var value_rating = parseFloat(rating.value_rating) || 3.0
      var taste_rating = parseFloat(rating.taste_rating) || 0.0
      return (value_rating + taste_rating) / 2.0;
    }
    else {
      return 0;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4">Ratings</Typography>
        <InfiniteScroll
          dataLength={this.state.ratings.length}
          next={this.fetchMoreRatings}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}
        >
        {this.state.ratings.length > 0 ? (
          <Paper elevation={1} className={classes.ratings}>
            <List>
              {orderBy(this.state.ratings, ['name'], ['description']).map(rating => (
                <ListItem key={rating.name} button component={Link} to={`/ratings/${rating.name}`}>
                  <ListItemText
                    primary={rating.name}
                    secondary={<Rating defaultValue={this.averageRatingValue(rating)} precision={0.25} size="large" readOnly />}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => this.deleteRating(rating)} color="inherit">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        ) : (
          !this.state.loading && <Typography variant="subtitle1">No ratings to display</Typography>
        )}
        </InfiniteScroll>

        <Fab
          color="secondary"
          aria-label="add"
          className={classes.fab}
          component={Link}
          to="/ratings/new">
          <AddIcon />
        </Fab>
        {this.state.error && (
          <ErrorSnackbar
            onClose={() => this.setState({ error: null })}
            message={this.state.error.message}
          />
        )}
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
)(RatingsManager);
