import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Typography,
} from '@material-ui/core';
import { compose } from 'recompose';
import SignInButton from '../components/SignInButton';


const styles = theme => ({
  ratings: {
    marginTop: theme.spacing(2),
    "overflow-y": 'auto',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
});

class SignInPage extends Component {
  constructor (props, context) {
    super(props, context);
    window.successCallback = this.onSuccess;
  }

  onSuccess(response) {
    var base64Url = response.credential.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const responsePayload = JSON.parse(jsonPayload);
    window.localStorage.setItem('jinzu-auth', response.credential)
    window.localStorage.setItem('jinzu-auth-expires', responsePayload.exp)
    window.location = "/ratings";
  }

  render() {
    return (
      <Fragment>
        <Typography variant="h4">Sign in</Typography>
        <SignInButton autoPrompt="true"/>
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
)(SignInPage);


